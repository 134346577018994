import React from 'react'
import NotifySlider from './Slider';

const MainContent = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <p style={{ color: 'gray', fontFamily: 'Krona One, sans-serif', fontSize: 20 }}>We are busy building <span style={{color: '#03D069'}}>Portable EV Charger</span></p>
            <p style={{ color: 'gray', fontFamily: 'Krona One, sans-serif', fontSize: 20, marginTop: 0 }}>Stay tuned for updates</p>
            <div style={{ display: 'flex', flexDirection: 'row', width: '80%', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', alignItems: 'center' }}>
                <NotifySlider />
            </div>
        </div>
    )
}

export default MainContent