import './styles/App.css';
import AdaptCharge from './assets/AdaptCharge.png'
import MainContent from './components/MainContent';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <img src={AdaptCharge} alt="" style={{ height: '150px', width: '500px', bottom: 0, marginLeft: 'auto', marginRight: 'auto', marginBottom: '2%' }} />
        <MainContent/>
      </header>
    </div>
  );
}

export default App;
