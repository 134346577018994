import React, { useEffect, useState } from 'react';
import '../styles/Slider.css';
import notifyme from '../assets/notify_me.png'
import axios from 'axios';

const NotifySlider = () => {
    const [isOn, setIsOn] = useState(false);
    const [email, setEmail] = useState('');
    const [showInput, setShowInput] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {
        if (isOn) {
            const timer = setTimeout(() => setShowInput(true), 300);
            return () => clearTimeout(timer);
        } else {
            setShowInput(false);
        }
    }, [isOn]);

    const handleToggle = () => {
        setIsOn(true);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleNotify = async () => {
        if (validateEmail(email)) {
            try {
                const response = await axios.post('https://sandbox.adaptcharge.io/notify', { email: email })
                if (response.status === 200) {
                    setHasSubmitted(true)
                }   
            } catch (error) {
                console.log('Error occurred in API call:', error);
            }
        } else{
            alert('Invalid Email Entered!')
        }
    };

    return (
        <div className={`notify-slider ${isOn ? 'on' : ''}`} onClick={isOn ? () => { } : handleToggle}>
            {!isOn && <span className="notify-text" style={{fontFamily: 'Krona One, sans-serif'}}>{`Notify Me >`}</span>}
            {hasSubmitted ? (
                <span className="thank-you-message" style={{fontFamily: 'Krona One, sans-serif', fontSize: 15}}>Thank you for your interest</span>
            ) : (
                <>
                    <div className="input-container">
                        {showInput && (
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Enter your email"
                                className="email-input"
                                autoFocus
                                style={{fontFamily: 'Krona One, sans-serif'}}
                            />
                        )}
                    </div>
                    <button
                        onClick={isOn ? handleNotify : handleToggle}
                        className="notify-button"
                    >
                        <img className="button-text" src={notifyme} style={{ height: '60px', width: '60px', marginLeft: '5px' }} alt='mail-icon' />
                    </button>
                </>
            )}
        </div>
    );
};

export default NotifySlider;
